import { createStore } from 'vuex'

export default createStore({
  state: {
    userName: '',
    userId: '',
    userMemId: '',
    userCell: '',
    userTeam: '',

    isChristMasSeason: false,
    isXmasAdmin: false,
    judgeYn: 'N',
    concertCd: '',
    concertNm: '',
    partCd: '',
    partNm: '',

    searchTitle: '',
    searchCell: '',
    searchName: '',
    searchUseChk: false,
    searchConfirm: false,
    searchOrderCond: 1
  },
  getters: {},
  mutations: {
    setUserName(state, payload) {
      state.userName = payload
    },
    setJudgeYn(state, payload) {
      state.judgeYn = payload
    }
  },
  actions: {},
  modules: {}
})
