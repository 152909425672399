<template>
  <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
    <!-- Navbar Brand-->
    <a class="navbar-brand ps-3">MyPage</a>
    <!-- Sidebar Toggle-->
    <button
      class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
      id="sidebarToggle"
      href="#!"
    >
      <i class="fas fa-bars"></i>
    </button>
    <div style="width: 400px; text-align: center" class="sb-sidenav-dark">
      {{ this.$store.state.userName }} ({{ this.$store.state.userTeam }}
      {{ this.$store.state.userCell }}) [{{ this.$store.state.userMemId }}]
    </div>
  </nav>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div class="sb-sidenav-menu">
          <div class="nav">
            <a
              v-if="isMinister"
              class="nav-link"
              @click="goPage('pastorSogamList')"
            >
              <div class="sb-nav-link-icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              소감리스트(목사님전용)
            </a>
            <a v-else class="nav-link" @click="goPage('sogamList')">
              <div class="sb-nav-link-icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              소감리스트
            </a>
            <a class="nav-link" @click="goPage('sogamWrite')">
              <div class="sb-nav-link-icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              소감쓰기
            </a>
            <!--
            <a class="nav-link" @click="goPage('choiceWord')">
              <div class="sb-nav-link-icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              말씀뽑기
            </a>
            <a class="nav-link" @click="goPage('seeWord')">
              <div class="sb-nav-link-icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              말씀보기
            </a>
-->
            <a v-if="isJudgeView" class="nav-link" @click="goPage('judge')">
              <div class="sb-nav-link-icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              심사위원 채점
            </a>

            <a
              v-if="isChristMasSeason"
              class="nav-link"
              @click="goPage('adultRank')"
            >
              <div class="sb-nav-link-icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              콘서트 투표
            </a>
            <a
              v-if="isXmasAdminView"
              class="nav-link"
              @click="goPage('xmasAdmin')"
            >
              <div class="sb-nav-link-icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              콘서트 관리자
            </a>
          </div>
        </div>

        <div class="sb-sidenav-footer">
          <div class="small">Logged in as1:</div>
          Start
        </div>
      </nav>
    </div>
    <div id="layoutSidenav_content">
      <main>
        <router-view />
      </main>
    </div>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {
      userName: this.$store.state.userName,
      userCell: this.$store.state.userCell,
      userTeam: this.$store.state.userTeam,
      userMemId: this.$store.state.userMemId,
      userId: this.$store.state.userId,
      judgeYn: this.$store.state.judgeYn,
      isChristMasSeason: this.$store.state.isChristMasSeason,
      isMinister: false,
      isJudgeView: false,
      isXmasAdminView: false
    }
  },
  mounted() {},
  updated() {
    console.log('userId updated ::' + this.$store.state.userId)
    if (
      this.$store.state.userId === 'ehchung' ||
      this.$store.state.userId === 'sahra1123'
    ) {
      this.isMinister = true
    } else {
      this.isMinister = false
    }
    console.log('isJudge updated ::' + this.$store.state.judgeYn)

    this.isChristMasSeason = this.$store.state.isChristMasSeason
    if (this.$store.state.judgeYn === 'Y' && this.isChristMasSeason) {
      this.isJudgeView = true
    } else {
      this.isJudgeView = false
    }

    if (this.$store.state.isXmasAdmin) {
      this.isXmasAdminView = true
    } else {
      this.isXmasAdminView = false
    }
  },
  methods: {
    goPage(url) {
      if (this.$store.state.userId === 'Err') {
        alert('비정상적인 접속입니다.')
        return
      }
      this.$router.push({
        name: url
      })

      document.body.classList.toggle('sb-sidenav-toggled')
    }
  }
}
</script>
