import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sogamWrite',
    name: 'sogamWrite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sogamWrite" */ '../views/SogamWriteView.vue')
  },
  {
    path: '/sogamList',
    name: 'sogamList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sogamList" */ '../views/SogamListView.vue')
  },
  {
    path: '/sogamSee',
    name: 'sogamSee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "sogamSee" */ '../views/SogamSeeView.vue')
  },
  {
    path: '/pastorSogamList',
    name: 'pastorSogamList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pastorSogamList" */ '../views/PastorSogamListView.vue'
      )
  },
  {
    path: '/pastorSogamSee',
    name: 'pastorSogamSee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pastorSogamSee" */ '../views/PastorSogamSeeView.vue'
      )
  },
  {
    path: '/choiceWord',
    name: 'choiceWord',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "choiceWord" */ '../views/ChoiceWordView.vue')
  },
  {
    path: '/seeWord',
    name: 'seeWord',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "seeWord" */ '../views/SeeWordView.vue')
  },
  {
    path: '/judge',
    name: 'judge',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "judge" */ '../views/JudgeView.vue')
  },
  {
    path: '/adultRank',
    name: 'adultRank',
    // route level code-splitting
    // this generates a separate chunk (about[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "adultRank" */ '../views/AdultRankView.vue')
  },
  {
    path: '/xmasAdmin',
    name: 'xmasAdmin',
    // route level code-splitting
    // this generates a separate chunk (about[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "xmasAdmin" */ '../views/XmasAdminView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
